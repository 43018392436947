export default function Football() {
    return (
        <svg className="rot" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
             xmlnsXlink="http://www.w3.org/1999/xlink" x="490" y="1000" width="100" height="100"
             viewBox="0 0 50.536 50.494" xmlSpace="preserve">
            <g>
            <path d="M2.104,48.394c2.477,2.474,8.728,2.711,15.731,0.979L1.119,32.671C-0.61,39.667-0.378,45.918,2.104,48.394z"></path>
            <path d="M12.165,12.157c-4.759,4.754-8.23,11.289-10.217,17.566l18.837,18.822c6.282-1.986,12.823-5.449,17.584-10.208
 c4.758-4.755,8.229-11.291,10.217-17.567L29.749,1.949C23.467,3.936,16.923,7.404,12.165,12.157z M37.008,20.26
 c0.521,0.519,0.521,1.367-0.002,1.886c-0.258,0.263-0.603,0.391-0.944,0.391c-0.343,0-0.683-0.128-0.944-0.391l-2.312-2.311
 l-1.822,1.817l2.312,2.315c0.525,0.519,0.525,1.367,0,1.886c-0.258,0.264-0.6,0.393-0.943,0.393s-0.682-0.128-0.943-0.393
 l-2.313-2.31l-1.821,1.816l2.312,2.315c0.525,0.522,0.525,1.367,0,1.888c-0.258,0.263-0.602,0.394-0.945,0.394
 s-0.682-0.13-0.944-0.394l-2.312-2.312l-1.823,1.817l2.313,2.314c0.524,0.521,0.524,1.367,0,1.889
 c-0.259,0.264-0.604,0.393-0.944,0.393c-0.339,0-0.682-0.128-0.946-0.393l-2.311-2.313l-1.305,1.304
 c-0.192,0.188-0.429,0.312-0.674,0.359l2.473,2.471c0.521,0.521,0.521,1.367,0,1.889c-0.262,0.26-0.606,0.392-0.949,0.392
 c-0.339,0-0.682-0.132-0.944-0.392L13.53,30.24c-0.521-0.524-0.521-1.372,0-1.894c0.521-0.521,1.367-0.521,1.889,0l2.696,2.694
 c0.048-0.245,0.172-0.481,0.364-0.668l1.305-1.304l-2.543-2.541c-0.522-0.522-0.522-1.367,0-1.889c0.522-0.519,1.367-0.519,1.888,0
 l2.545,2.543l1.818-1.822l-2.541-2.539c-0.526-0.52-0.526-1.365,0-1.891c0.522-0.52,1.368-0.52,1.889,0l2.544,2.543l1.818-1.821
 l-2.541-2.539c-0.52-0.524-0.52-1.37,0-1.891c0.522-0.522,1.368-0.522,1.89,0l2.539,2.541l1.822-1.819l-2.539-2.539
 c-0.526-0.522-0.526-1.367,0-1.886c0.521-0.526,1.367-0.526,1.89,0L37.008,20.26z"></path>
            <path
                d="M48.434,2.1c-2.482-2.475-8.732-2.71-15.736-0.98l16.717,16.704C51.147,10.82,50.911,4.577,48.434,2.1z"></path>
            </g>
        </svg>
    )
}