export async function getScores() {
    const max = 30
    const min = 10
    const timeout = Math.random() * (max - min) + min;

    const controller = new AbortController()
    const timeoutId = setTimeout(() => controller.abort(), timeout)

    const response = await fetch('/api/scores', {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
            'Content-Type': 'application/json'
        },
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
    }).then(d => {
        clearTimeout(timeoutId)
        return d
    });
    return response.json(); // parses JSON response into native JavaScript objects
}