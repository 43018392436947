import 'animate.css'
import '../css/Wall.css'
import Score from "./Score";
import WhiteRect from "./WhiteRect";
import Football from "./Football";

export default function Wall({scores}) {

    let y = 709
    let delta = 217

    let rects = []
    for (let i = 0; i < 5; i++) {
        if (i >= scores.length) {
            rects = [...rects, <WhiteRect key={i} y={y + (delta * i)} />]
        }
    }

    let emptyImage = null

    if (scores.length === 0) {
        console.log('empty')
        emptyImage = <Football />
    }

    return (

        <svg version="1.1"
             width="1080"
             height="1920"
             xmlns="http://www.w3.org/2000/svg">

            <image href="static/wall/bg.png" height="1920" width="1080" />

            {emptyImage}

            {scores.map((score, i) => <Score key={score.id} score={score.score} name={score.name} y={y + delta * i} />)}

            {rects}
        </svg>
    )
}