export default function Score({name, score, y}) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" x="202" y={y} width="754" height="148" className={'animate__animated animate__fadeIn'}
             style={{
                 opacity: 0
             }}
        >
            <rect xmlns="http://www.w3.org/2000/svg" x="0" y="0" width="100%" height="100%" fill="transparent" stroke="black" strokeWidth="16"/>
            <text x="34" y="95">{name}</text>
            <rect xmlns="http://www.w3.org/2000/svg" x="576" y="26" width="155" height="99" fill="black"/>
            <text x="600" y="96" fill="white"
                  style={{textAlign: 'center'}}
                  textAnchor="middle" alignmentBaseline="central">
                <tspan dx="55" dy="0">{ score }</tspan>
            </text>
        </svg>
    );
}