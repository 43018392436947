import React, {useEffect, useState} from 'react'
import '../css/App.css';
import Wall from "./Wall";
import {getScores} from '../Api'

let t = new Date().getTime()
const timeout = 2000

function App() {
    const [scores, setScores] = useState([])

    useEffect(() => {
        let timer;
        const f = () => {
            if (new Date().getTime() - t < timeout / 2) {
                return
            }
            getScores()
                .then(newScores => {
                    setScores(newScores)
                })
                .catch(e => console.error(e))
            t = new Date()
        }

        f()

        timer = setInterval(f, timeout)

        return () => clearInterval(timer);
    }, [scores]);


    return (
        <div className="App">
            <Wall scores={scores}/>
        </div>
    );
}

export default App;
